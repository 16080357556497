import CallIcon from '@mui/icons-material/Call';
import FacebookIcon from '@mui/icons-material/Facebook';
import HandshakeIcon from '@mui/icons-material/Handshake';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import LogoWhite from '../../components/LogoWhite';
import { BLOG_PATH, PATH_PAGE } from '../../routes/paths';
import { GtmEvent } from '../../utils/googleTagManager';
import { SocialLink } from '../../_mock/socialLink';
import { callNumber, whatsAppNumber } from '../../_mock/contactInfo';

const BoxSection = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  py: 2,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const NeedHelpSection = styled(Box)(({ theme }) => ({
  position: 'fixed',
  alignItems: 'center',
  backgroundColor: '#25d366',
  color: '#fff',
  borderRadius: '40px 40px',
  bottom: '2em',
  zIndex: '1',
  // boxShadow: "0 4px 12px 0 rgb(0 0 0 / 15 %)",
  cursor: 'pointer',
  display: 'flex',
  height: 60,
  width: 60,
  justifyContent: 'center',
  right: '1rem',
}));

export default function MainFooter2() {
  const { facebookLink, instagramLink, youtubeLink, twitterLink, linkedinLink, pinterestLink } = SocialLink;
  const { pathname } = useLocation();

  // const isHome = pathname === '/';

  const handlaEvent = () => {
    GtmEvent(`b_whatsapp_+91${whatsAppNumber}`);
  };
  return (
    <>
      <NeedHelpSection onClick={handlaEvent}>
        <Button href={`//api.whatsapp.com/send?phone=91${whatsAppNumber}`} target="_default">
          <Iconify icon="logos:whatsapp-icon" sx={{ width: 30, height: 30, m: 0.5 }} />
        </Button>
      </NeedHelpSection>

      {/* <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: "url(https://get.clt.re/wp-content/uploads/2014/12/footer-background-01.jpg)", p: { md: 3, xs: "10px" }, py: 5, position: "relative" }}>

                <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "space-around", p: { md: 3, xs: "3px" }, py: 1, background: "url(https://get.clt.re/wp-content/uploads/2014/12/footer-background-01.jpg)", backgroundSize: "cover", borderRadius: "20px", width: "90%" }}>
                    <Grid item xs={0}>
                        <Box>
                            <Typography sx={{ letterSpacing: "-0.04rem", fontWeight: "500", color: "white", fontSize: { md: '30px', xs: '24px' } }} variant="h5">Need Help To Setup Your Free Fundraiser?
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={0}>
                        <Button variant="contained" sx={{ m: 2, p: 1, fontWeight: "500", borderRadius: "50px", fontSize: { md: '16px', xs: '16px' }, px: 3 }} href="tel:+919125239004">
                            CALL SUPPORT
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
             */}
      <BoxSection>
        <Grid container sx={{ minHeight: '25vh', py: 5, backgroundColor: '#385f96' }}>
          <Grid
            item
            xs={0}
            sx={{
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              pb: 3,
            }}
          >
            <LogoWhite sx={{ width: { xs: 150, md: 200 } }} />
          </Grid>
          <Grid
            item
            xs={100}
            md={6}
            sx={{
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <Grid container sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
              <Grid item>
                <Button href="/" sx={{ color: 'white', px: 2, fontWeight: '500', fontSize: '16px' }}>
                  Home
                </Button>
              </Grid>
              <Grid item>
                <Button href={PATH_PAGE.about} sx={{ color: 'white', px: 2, fontWeight: '500', fontSize: '16px' }}>
                  About us
                </Button>
              </Grid>
              <Grid item>
                <Button href={PATH_PAGE.contact} sx={{ color: 'white', px: 2, fontWeight: '500', fontSize: '16px' }}>
                  Contact us
                </Button>
              </Grid>
              <Grid item>
                <Button href={BLOG_PATH.blog} sx={{ color: 'white', px: 2, fontWeight: '500', fontSize: '16px' }}>
                  Blog
                </Button>
              </Grid>
              <Grid item>
                <Button href={PATH_PAGE.faqs} sx={{ color: 'white', px: 2, fontWeight: '500', fontSize: '16px' }}>
                  FAQs
                </Button>
              </Grid>
              <Grid item>
                <Button href={PATH_PAGE.terms} sx={{ color: 'white', px: 2, fontWeight: '500', fontSize: '16px' }}>
                  Terms
                </Button>
              </Grid>
            </Grid>

            {/* <Box component="img" src="https://www.impaac.org/wp-content/uploads/2020/03/impaac-footer-secured-card.png" sx={{ maxWidth: { xs: "90vw" }, pt: 3 }}>.</Box> */}
            <Grid
              container
              sx={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: { xs: 'start', sm: 'center' },
                background: '#2e4f7e',
                mt: 3,
                p: 2,
                maxWidth: { md: '450px', xs: '90vw' },
                margin: '30px auto',
                my: 2,
                borderRadius: '40px',
              }}
            >
              <Grid item xs={2}>
                <Button href={instagramLink} sx={{ color: 'white' }} target="_blank">
                  <InstagramIcon sx={{ fontSize: '30px' }} />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button href={youtubeLink} sx={{ color: 'white' }} target="_blank">
                  <YouTubeIcon sx={{ fontSize: '30px' }} />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button href={linkedinLink} sx={{ color: 'white' }} target="_blank">
                  <LinkedInIcon sx={{ fontSize: '30px' }} />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button href={twitterLink} sx={{ color: 'white' }} target="_blank">
                  <TwitterIcon sx={{ fontSize: '30px' }} />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button href={facebookLink} sx={{ color: 'white' }} target="_blank">
                  <FacebookIcon sx={{ fontSize: '30px' }} />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button href={pinterestLink} sx={{ color: 'white' }} target="_blank">
                  <PinterestIcon sx={{ fontSize: '30px' }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={100}
            md={3}
            item
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Grid container display="block">
              <Grid item>
                <Button
                  href={PATH_PAGE.howItWorks}
                  sx={{ color: 'white', padding: '0', fontWeight: '500', fontSize: '16px' }}
                  startIcon={<MenuBookIcon />}
                >
                  How it Works
                </Button>
              </Grid>
              <Grid item>
                <Button
                  href={PATH_PAGE.campaignNew}
                  sx={{ color: 'white', padding: '0', fontWeight: '500', fontSize: '16px' }}
                  startIcon={<HandshakeIcon />}
                >
                  Create a Fundraiser
                </Button>
              </Grid>
            </Grid>
            <Grid container display="block" sx={{ mt: 2 }}>
              <Grid item>
                <Button
                  href={`tel:${callNumber}`}
                  sx={{ color: 'white', padding: '0', fontWeight: '500', fontSize: '16px' }}
                  startIcon={<CallIcon />}
                >
                  Call Us : {callNumber}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  href="mailto:connect@impaac.org"
                  sx={{ color: 'white', padding: '0', fontWeight: '500', fontSize: '16px' }}
                  startIcon={<MailIcon />}
                >
                  Email :&nbsp; <span style={{ textTransform: 'lowercase' }}>connect@impaac.org</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BoxSection>
      <Grid
        sx={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          background: '#2e4f7e',
          p: 2,
          alignItems: 'center',
        }}
      >
        <Typography sx={{ color: 'white' }}>
          © 2024 Impaac Foundation. All rights reserved &nbsp;|
          <Button
            href={PATH_PAGE.privacypolicy}
            sx={{ margin: 'auto', color: '#1BB0CE', fontWeight: '500', fontSize: '16px' }}
          >
            Privacy Policy
          </Button>
        </Typography>
      </Grid>
    </>
  );
}
